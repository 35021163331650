<template>
  <b-container class="login">
    <b-row>
      <b-col></b-col>
      <b-col align-self="center">
        <b-card header-bg-variant="transparent">
          <template slot="header">
            <div class="logo">
              <img alt="Vue logo" src="../assets/img/logo.png">
              Welcome
            </div>
          </template>
          <b-form @submit.prevent="handleLogin">
            <b-input-group class="margin-top-bottom-10">
              <b-input-group-prepend is-text>
                <b-icon-person-fill/>
              </b-input-group-prepend>
              <b-form-input
                  id="input-userName"
                  v-model="form.userName"
                  placeholder="Username"
                  required
              ></b-form-input>
            </b-input-group>
            <b-input-group class="margin-top-bottom-10">
              <b-input-group-prepend is-text>
                <b-icon-lock-fill/>
              </b-input-group-prepend>
              <b-form-input
                  id="input-password"
                  v-model="form.password"
                  type="password"
                  placeholder="Password"
                  required
              ></b-form-input>
            </b-input-group>
            <b-button type="submit" variant="primary" block :pressed="loading"
                      style="margin-top: 30px;">Login
            </b-button>
            <b-form-row class="text-center margin-top-bottom-10">
              <b-col>
                <b-link @click="goBack">Back</b-link>
              </b-col>
            </b-form-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col></b-col>
    </b-row>
  </b-container>
</template>

<script>
import {salt, setting} from '../assets/js/config.js';
import {MD5} from '../assets/js/util.js';

export default {
  name: "Login",
  data() {
    return {
      form: {
        userName: '',
        password: ''
      },
      loading: false
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;

      this.$axios.post('login', {
        userName: this.form.userName,
        password: MD5(this.form.password + salt.password)
      })
          .then(res => {
            this.loading = false;

            if (res.s === 0) {
              this.$store.dispatch('common/login', res.data).then(() => {
                this.$router.push({path: '/welcome'});
              });
            } else {
              this.$bvToast.toast(res.msg, {
                title: '系统提示',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(err => {
            this.loading = false;

            console.error(err);
          });
    },
    goBack() {
      window.location.href = setting.url.web
    }
  }
}
</script>

<style lang="scss">
.login {
  height: 100%;

  .row {
    height: 80%;
  }

  .logo {
    text-align: center;

    img {
      width: 50px;
    }
  }

  .margin-top-bottom-10 {
    margin: 10px 0;
  }
}
</style>